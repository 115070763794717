import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from '../pages/Login'
import FreeHome from '../pages/FreeHome'
import FreeCustomerService from '../pages/FreeCustomerService'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import TermsAndConditions from '../pages/TermsandConditions'



function AuthRoutes() {
    return (

        <Routes >
            <Route path='/login' element={<Login />} />
            <Route path='' element={<FreeHome />} />
            <Route path='/customer-service-unauthorized' element={<FreeCustomerService />} />
            <Route path='/speechtotext/transcribe' element={<Navigate to="/" />} />
            <Route path='/privecypolicy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<TermsAndConditions />} />
        </Routes>
    )
}

export default AuthRoutes
