import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData"
import { GLOBALTYPES } from "./GlobalTypes"

export const USER_TYPES = {
    LOADING: "LOADING",
    CREDITS: "CREDITS",
    HOME: "HOME"
    // GET_SELECTED_FEATURES: "GET_SELECTED_FEATURES",
}

export const get_user_credits = (token) => async (dispatch) => {
    try {
        // dispatch({ type: USER_TYPES.LOADING, payload: { loading: true } })
        const res = await getDataAPI(`user/get_user_credits`, token)
        dispatch({
            type: USER_TYPES.CREDITS,
            payload: {
                credits: res?.data?.data?.credits,
                prompt: res?.data?.data?.prompt,
                promptTwo: res?.data?.data?.promptTwo,
                promptThree: res?.data?.data?.promptThree
            }
        })

        dispatch({
            type: USER_TYPES.LOADING,
            payload: {
                loading: false
            }
        })
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const update_user_activity = (userActivityMessage, token) => async (dispatch) => {
    try {
        await putDataAPI(`user/update_user_activity`, { userActivityMessage }, token)
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}
export const create_record_activity = (body, token) => async (dispatch) => {
    try {
        await postDataAPI(`user/create_record_activity`, body, token)
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
} 