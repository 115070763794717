import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData";
import { GLOBALTYPES } from "./GlobalTypes";
import { get_user_credits } from "./UserAction";


export const PAYMENT_TYPES = {
    PAYMENT: "PAYMENT",
}

export const PAYMENT_HISTORY_TYPES = {
    PAYMENT_HISTORY: "PAYMENT_HISTORY",
}

export const update_credit = async (recordTime, token) => async (dispatch) => {
    try {
        await putDataAPI(`user/update_credit`, { recordTime }, token)
        dispatch(get_user_credits(token))
        return;
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const paypal_recharge = async ({ data, token }) => async (dispatch) => {
    try {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                loading: true
            }
        })

        let res;


        if (data?.index === 1) {
            res = await postDataAPI(`user/first_plan_purchase`, data, token)
        }
        else if (data?.index === 2) {
            res = await postDataAPI(`user/second_plan_purchase`, data, token)
        }
        else if (data?.index === 3) {
            res = await postDataAPI(`user/third_plan_purchase`, data, token)
        }
        else {
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'something went wrong'
                }
            })
        }

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res?.data?.message
            }
        })
    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const paypal_purchase_history = (token) => async (dispatch) => {
    try {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                loading: true
            }
        })

        const res = await getDataAPI(`user/payment_purchase_history`, token)


        dispatch({
            type: PAYMENT_HISTORY_TYPES.PAYMENT_HISTORY,
            payload: {
                payment_history: res.data.data
            }
        })

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                loading: false
            }
        })
    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}