import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { paypal_purchase_history } from '../redux/actions/PaymentAction'
import { plansDefault } from '../common/plan'
import { Helmet } from 'react-helmet'
import { update_user_activity } from '../redux/actions/UserAction'

export default function PurchaseHistory() {
    const token = localStorage.getItem('repeatMeToken')
    const purchaseHistory = useSelector(state => state.payment?.payment_history)

    const dispatch = useDispatch()
    useEffect(() => {
        let userActivityMessage = "Purchase History"
        dispatch(update_user_activity(userActivityMessage, token))
    }, [dispatch, token]);
    useEffect(() => {
        if (token) {
            dispatch(paypal_purchase_history(token))
        }
    }, [dispatch, token])

    return (
        <>
            <Helmet>
                <title>Purchase History - SpeechToText.live</title>
                <meta name="description" content="View your purchase history and transaction details." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <Navbar />

            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-[20px]">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-black dark:text-gray-400 ">

                        <tr className="">
                            <th scope="col" className="px-6 py-3 text-center ">
                                Plan Type
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Price
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Credits
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Purchased At
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        {purchaseHistory?.length > 0 ?
                            purchaseHistory?.map((item, index) => (

                                <tr key={index} className="bg-white  dark:bg-black dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.planType}
                                    </th>
                                    <td className="px-6 py-4 text-center">
                                        {item.price}$
                                    </td>
                                    <td className="px-6 py-4 text-center">
                                        {Math.floor(plansDefault.find(x => Number(x.price) === Number(item.price))?.credits / 60)} minutes
                                    </td>
                                    <td className="px-6 py-4 text-center">
                                        {item?.createdAt?.split('T')[0]}
                                    </td>
                                </tr>
                            ))

                            :

                            <tr className="bg-white dark:bg-black dark:border-gray-700">
                                <td colSpan="4" className="px-6 py-4 text-center text-gray-700 dark:text-gray-300">
                                    No data available
                                </td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
        </>

    )
}
