import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_user_credits, update_user_activity } from '../../redux/actions/UserAction'
import Navbar from '../Navbar'
import { Helmet } from 'react-helmet'

function ProfileCard() {
  const token = localStorage.getItem('repeatMeToken')
  const dispatch = useDispatch();
  const credits = useSelector(state => state.user?.credits)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)
  const isDarkTheme = useSelector(state => state.theme?.theme)

  useEffect(() => {
    let userActivityMessage = "Profile Page"
    dispatch(update_user_activity(userActivityMessage, token))
  }, [dispatch, token]);

  useEffect(() => {
    function formatTime(credits) {
      const minutes = Math.floor(credits / 60);
      const remainingSeconds = credits % 60;
      setMinute(minutes);
      setSecond(remainingSeconds);
    }

    if (credits !== undefined) {
      formatTime(credits);
    }
  }, [credits]);

  useEffect(() => {
    if (token) {
      dispatch(get_user_credits(token))
    }
  }, [dispatch, token])
  const userDetails = JSON.parse(localStorage.getItem('userRepeatMe'))

  return (
    <>
      <style>
        {`
                .wrapper,
                .wrapper .img-area,
                .social-icons a,
                .buttons button{
                  background: #ecf0f3;
                //   box-shadow: -3px -3px 7px #ffffff,
                //                3px 3px 5px #ceced1;
                }
                .wrapper{
                //   position: relative;
                  width: 350px;
                  padding: 30px;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }
                .wrapper .icon{
                  font-size: 17px;
                  color: #31344b;
                  position: absolute;
                  cursor: pointer;
                  opacity: 0.7;
                  top: 15px;
                  height: 35px;
                  width: 35px;
                  text-align: center;
                  line-height: 35px;
                  border-radius: 50%;
                  font-size: 16px;
                }
                .wrapper .icon i{
                  position: relative;
                  z-index: 9;
                }
                .wrapper .icon.arrow{
                  left: 15px;
                }
                .wrapper .icon.dots{
                  right: 15px;
                }
                .wrapper .img-area{
                  height: 150px;
                  width: 150px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .img-area .inner-area{
                  height: calc(100% - 25px);
                  width: calc(100% - 25px);
                  border-radius: 50%;
                }
                .inner-area img{
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
                .wrapper .name{
                  font-size: 23px;
                  font-weight: 500;
                  color: #31344b;
                  margin: 10px 0 5px 0;
                }
                .wrapper .about{
                  color: #44476a;
                  font-weight: 400;
                  font-size: 16px;
                }
                .wrapper .social-icons{
                  margin: 15px 0 25px 0;
                }
                .social-icons a{
                  position: relative;
                  height: 40px;
                  width: 40px;
                  margin: 0 5px;
                  display: inline-flex;
                  text-decoration: none;
                  border-radius: 50%;
                }
                .social-icons a:hover::before,
                .wrapper .icon:hover::before,
                .buttons button:hover:before{
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  border-radius: 50%;
                  background: #ecf0f3;
                //   box-shadow: inset -3px -3px 7px #ffffff,
                //               inset 3px 3px 5px #ceced1;
                }
                .buttons button:hover:before{
                  z-index: -1;
                  border-radius: 5px;
                }
                .social-icons a i{
                  position: relative;
                  z-index: 3;
                  text-align: center;
                  width: 100%;
                  height: 100%;
                  line-height: 40px;
                }
                .social-icons a.fb i{
                  color: #4267B2;
                }
                .social-icons a.twitter i{
                  color: #1DA1F2;
                }
                .social-icons a.insta i{
                  color: #E1306C;
                }
                .social-icons a.yt i{
                  color: #ff0000;
                }
                .wrapper .buttons{
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                }
                .buttons button{
                  position: relative;
                  width: 100%;
                  border: none;
                  outline: none;
                  padding: 12px 0;
                  color: #31344b;
                  font-size: 17px;
                  font-weight: 400;
                  border-radius: 5px;
                  cursor: pointer;
                  z-index: 4;
                }
                .buttons button:first-child{
                  margin-right: 10px;
                }
                .buttons button:last-child{
                  margin-left: 10px;
                }
                .wrapper .social-share{
                  display: flex;
                  width: 100%;
                  margin-top: 30px;
                  padding: 0 5px;
                  justify-content: space-between;
                }
                .social-share .row{
                  color: #31344b;
                  font-size: 17px;
                  cursor: pointer;
                  position: relative;
                }
                .social-share .row::before{
                  position: absolute;
                  content: "";
                  height: 100%;
                  width: 2px;
                  background: #e0e6eb;
                  margin-left: -25px;
                }
                .row:first-child::before{
                  background: none;
                }
                .social-share .row i.icon-2{
                  position: absolute;
                  left: 0;
                  top: 50%;
                  color: #31344b;
                  transform: translateY(-50%);
                  opacity: 0;
                  pointer-events: none;
                  transition: all 0.3s ease;
                }
                .row:nth-child(1):hover i.fa-heart,
                .row:nth-child(2):hover i.fa-comment{
                  opacity: 1;
                  pointer-events: auto;
                }
                .card {
                    margin-top: 10px;
                    width: 230px;
                    height: 280px;
                    border-radius: 20px;
                    padding: 10px;
                    // background-color: white;
                    // box-shadow: 5px 5px 30px rgb(4, 4, 4),
                                     -5px -5px 30px rgb(57, 57, 57);
                  }
                  
                  .profileimage {
                    // background-color: transparent;
                    border: none;
                    margin-top: 15px;
                    border-radius: 20px;
                    width: 80px;
                    height: 80px;
                  }
                  
                  .pfp {
                    // border-radius: 35em;
                    fill: white;
                  }
                  
                  .Name {
                    color: white;
                    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
                    padding: 15px;
                    font-size: 20px;
                    margin-top: 0px;
                  }
                  
                  .socialbar {
                    border-radius: 10px;
                    width: 90%;
                    padding: 14px;
                    margin-top: 10px;
                    color: white;

                    // box-shadow: 3px 3px 15px rgb(0, 0, 0),
                                    //  -3px -3px 15px rgb(58, 58, 58);
                  }  `}
      </style>
      <Helmet>
        <title>Profile - SpeechToText.live</title>
        <meta name="description" content="View your profile information and credits." />
      </Helmet>
      <Navbar />
      <div className="wrapper flex justify-center items-center text-center mx-auto mt-[50px]" style={isDarkTheme ? { backgroundColor: "#111111" } : {}}>
        <div className="img-area">
          <div className="inner-area">
            <img src={userDetails?.image} alt="" />
          </div>
        </div>
        <div className="icon arrow"><i className="fas fa-arrow-left"></i></div>
        <div className="icon dots"><i className="fas fa-ellipsis-v"></i></div>
        <div className="name" style={isDarkTheme ? { color: 'white' } : {}}>{userDetails?.firstName} {userDetails?.lastName}</div>
        <div className="about" style={isDarkTheme ? { color: 'white' } : {}}>{userDetails?.email}</div>

        <div className="buttons mt-4 rounded-lg flex justify-center items-center dark:text-white" >
          <div className='text-center mx-auto'> Credits Left : {minute} min {second} sec</div>
          {/* <button>{minute} min {second} sec</button> */}
        </div>

      </div >

      {/* 
            <div className='bg-gray-100 p-2 rounded-lg'>
                <center>
                    <div className="profileimage">
                        <img className="mb-3 rounded-full shadow-lg" src={userDetails?.image} alt="Bonnie image" />
                    </div>
                    <div className="Name">
                        <p className='text-gray-900 font-bold dark:text-gray-200'>{userDetails?.firstName} {userDetails?.lastName}</p>
                    </div>
                    <div className={`socialbar ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} bg-gray-300`}>
                        <p className='text-gray-900 font-bold'>
                            Credits : {minute} min {second} sec
                        </p>
                    </div></center>
            </div> */}
    </>


  )
}

export default ProfileCard