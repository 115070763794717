import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer/index';
import { thunk } from 'redux-thunk'; // Corrected import for thunk
import { Provider } from 'react-redux';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: true,
});

const DataProvider = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

export default DataProvider;
