import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../assets/Logo.jpg';
import logo from '../assets/LogoZoom.jpg';
// import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux'
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';


const WithoutLoginNavbar = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [modalShow, setModalShow] = React.useState(false);
    const credits = useSelector((state) => state.fingerprint?.credits)

    const [minute, setMinute] = useState(0)
    const [second, setSecond] = useState(0)


    useEffect(() => {
        function formatTime(credits) {
            const minutes = Math.floor(credits / 60);
            const remainingSeconds = credits % 60;
            setMinute(minutes);
            setSecond(remainingSeconds);
        }

        if (credits !== undefined) {
            formatTime(credits);
        }
    }, [credits]);

    // useEffect(() => {
    //     dispatch({
    //         type: GLOBALTYPES.MODAL,
    //         payload: {
    //             modal: modalShow
    //         }
    //     })
    // }, [modalShow])

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [isDarkTheme, setIsDarkTheme] = useState(() => {
        const storedTheme = localStorage.getItem('color-theme');
        return storedTheme ? storedTheme === 'dark' : false; // Default to light if nothing is stored
    });

    // useEffect to apply the theme changes
    useEffect(() => {
        document.body.style.backgroundColor = isDarkTheme ? 'black' : 'white';
        if (isDarkTheme) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('color-theme', isDarkTheme ? 'dark' : 'light');

        // Dispatch theme state to Redux store if needed
        dispatch({
            type: GLOBALTYPES.THEME,
            payload: { theme: isDarkTheme }
        });
    }, [dispatch, isDarkTheme]);


    const toggleTheme = (e) => {
        e.preventDefault()
        setIsDarkTheme(!isDarkTheme);
    };

    return (
        <>
            <style>
                {`

.MuiPaper-root {
    background-color:${isDarkTheme ? '#111111' : 'white'} !important;
}
                .MuiList-padding{
                    padding:20px;
                    // border-radius:20px;
                   
                }
        //   .btn-custom:hover {
        //     background: linear-gradient(0deg, #0D5376, #0D5376);
        //     box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
        //                 inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
        //                 0px 0px 0px 4px rgba(255, 255, 255, 0.2),
        //                 0px 0px 180px 0px rgb(123,241,242);
        //     transform: translateY(-2px);
        //   }
        //   .btn-custom:hover .text-custom {
        //     color: white;
        //   }
          .toggle {
            background-color: white ;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            cursor: pointer;
            box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.1);
            line-height: 1;
          }
          
          .input {
            display: none;
          }
          
          .icon {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            transition: transform 500ms;
          }
          
          .icon--moon {
            transition-delay: 200ms;
            color: black;
          }
          
          .icon--sun {
            // transform: scale(0);
            color: yellow;
          }
          
        //   #switch:checked + .icon--moon {
        //     transform: rotate(360deg) scale(0);
        //   }
          
        //   #switch:checked ~ .icon--sun {
        //     transition-delay: 200ms;
        //     transform: scale(1) rotate(360deg);
        //   }

          .Btn {
            // width: 200px;
            // height: 40px;
            padding: 0px 20px;
            border: none;
            border-radius: 50px;
            background: linear-gradient(to right,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
            background: rgb(31 41 55); /* This sets the background to a medium gray */
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            // font-size: 1em;
            color: rgb(255, 255, 255);
            // color: rgb(121, 103, 3); /* You might want to change this color for better visibility */
            // font-weight: 600;
            cursor: pointer;
            position: relative;
            z-index: 2;
            transition-duration: 3s;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
            background-size: 200% 200%;
          }
          
          .logoIcon path {
            fill: rgb(121, 103, 3);
          }
          
          .Btn:hover {
            transform: scale(0.95);
            transition-duration: 3s;
            animation: gradient 5s ease infinite;
            background-position: right;
          }
          
          
        `}
            </style>

            <nav className="relative px-4 py-3 flex justify-between items-center bg-transparent  dark:bg-black border-b-2 dark:border-gray-600">
                <div className='flex items-center justify-center gap-4'>

                    <img src={logo} className='h-10 rounded-lg' alt="Logo" />
                    <Link className="text-lg lg:text-2xl font-extrabold text-[#0D5376] dark:text-white" to="/">
                        SpeechToText.live
                    </Link>
                </div>
                <div className="hidden md:flex md:gap-5">
                    <button id="theme-toggle" type="button" className="" onClick={toggleTheme}>
                        <label htmlFor="switch" className="toggle dark:bg-black" style={isDarkTheme ? { backgroundColor: 'black' } : {}}>
                            <input type="checkbox" className="input" id="switch" />
                            {!isDarkTheme ? <div className="icon icon--moon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    width="20"
                                    height="20"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                                : <div className="icon icon--sun">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        width="20"
                                        height="20"
                                    >
                                        <path
                                            d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"
                                        ></path>
                                    </svg>
                                </div>
                            }

                        </label>

                    </button>


                    <button className='btn-custom border-0 px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Credits : {minute} min {second} sec
                    </button>
                    <Link to="/customer-service-unauthorized" className='btn-custom border-0 px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Customer Service</Link>
                    <Link to="/login" className='btn-custom border-0 px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Login</Link>

                    {/* <Link to="/" className="Btn">
                        Buy Credits
                    </Link> */}
                    {/* <div>
                        <button className="btn-custom border-0 h-full px-[12px] rounded-[5px] flex justify-center items-center gap-3 bg-gray-800 cursor-pointer transition-all duration-500 ease-in-out" onClick={() => dispatch(logout())} >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='white' height={25} width={
                                25
                            }>
                                <path d="M20.9 11.6c-.1-.1-.1-.2-.2-.3l-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.3 1.3H13c-.6 0-1 .4-1 1s.4 1 1 1h4.6l-1.3 1.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3-3c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" />
                                <path d="M15.5 18.1c-1.1.6-2.3.9-3.5.9-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C15.1 3.4 13.6 3 12 3c-5 0-9 4-9 9s4 9 9 9c1.6 0 3.1-.4 4.5-1.2.5-.3.6-.9.4-1.4-.3-.4-.9-.6-1.4-.3z" />
                            </svg>
                        </button>
                    </div> */}
                </div>
                <div className="md:hidden flex items-center justify-between" >
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}

                    >
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" fill={`${isDarkTheme ? 'white' : 'black'}`} height="30" viewBox="0 0 50 50">
                            <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z"></path>
                        </svg>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        className='md:hidden w-[700px] flex justify-center items-center p-5  gap-[5px]'
                    // style={{ color: "red" }}
                    // style={{ gap: "10px" }}
                    >
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                        <button id="theme-toggle" type="button" className="my-2 ml-2" onClick={toggleTheme}>
                            <label htmlFor="switch" className="toggle dark:bg-black" style={isDarkTheme ? { backgroundColor: 'black' } : {}}>
                                <input type="checkbox" className="input" id="switch" />
                                {!isDarkTheme ? <div className="icon icon--moon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        width="20"
                                        height="20"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                    : <div className="icon icon--sun">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            width="20"
                                            height="20"
                                        >
                                            <path
                                                d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"
                                            ></path>
                                        </svg>
                                    </div>
                                }

                            </label>

                        </button>

                        <button className='btn-custom border-0 my-2 py-[10px] px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Credits : {minute} min {second} sec
                        </button>
                        <Link to="/customer-service-unauthorized" className='btn-custom border-0 my-2 py-[10px] px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Customer Service</Link>
                        <Link to="/login" className='btn-custom border-0 my-2 py-[10px] px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Login</Link>

                        {/* <Link to="/profile" className='btn-custom border-0 my-2 py-[10px] px-[20px] text-white  rounded-[5px] flex justify-center items-center gap-3 bg-[#0D5376] cursor-pointer transition-all duration-500 ease-in-out' style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px', backgroundColor: "rgb(31 41 55)" }} >Profile</Link>

                        <Link to="/plans" className="Btn " style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            Buy Credits
                        </Link>
                        <div className='py-[10px]'>
                       
                            <button className="btn-custom py-[10px] border-0 h-full px-[12px] rounded-[5px] flex justify-center items-center gap-3 bg-gray-800 cursor-pointer transition-all duration-500 ease-in-out" onClick={() => dispatch(logout())} >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='white' height={25} width={
                                    25
                                }>
                                    <path d="M20.9 11.6c-.1-.1-.1-.2-.2-.3l-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.3 1.3H13c-.6 0-1 .4-1 1s.4 1 1 1h4.6l-1.3 1.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3-3c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" />
                                    <path d="M15.5 18.1c-1.1.6-2.3.9-3.5.9-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C15.1 3.4 13.6 3 12 3c-5 0-9 4-9 9s4 9 9 9c1.6 0 3.1-.4 4.5-1.2.5-.3.6-.9.4-1.4-.3-.4-.9-.6-1.4-.3z" />
                                </svg>
                               
                            </button>
                        </div> */}
                    </Menu>

                </div>
            </nav >


        </>
    );
};

export default WithoutLoginNavbar;