// src/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/LogoZoom.jpg';

const FooterComponent = () => {
    // const Navigate = useNavigate();

    return (
        <>
            <hr className='dark:border-gray-700 border-gray-200 mt-[16px]' />
            <footer className="bg-purple-100 dark:bg-black h-auto text-gray-700 py-4  bottom-0 w-full">
                <div className="flex flex-col md:flex-row justify-between items-center mx-4 md:mx-20">
                    <div className="flex flex-col md:flex-row items-center md:items-start my-3 md:my-0">
                        <Link to="/terms" className=" hover:text-[#0D5376] text-sm md:mr-4 mb-2 md:mb-0 dark:text-gray-400 dark:hover:text-white">Terms of Service</Link>
                        <Link to='/privecypolicy' className="hover:text-[#0D5376] text-sm md:mr-4 dark:text-gray-400 dark:hover:text-white">Privacy Policy</Link>
                    </div>
                    <div className='mt-4 md:mt-0 dark:text-gray-400'>
                        <p>&copy;{new Date().getFullYear()} - SpeechtoText.live</p>
                    </div>
                </div>
                <div className='flex justify-center md:justify-start mt-4 md:mt-0 ml-0 md:ml-20'>
                    <div className='flex items-center justify-center gap-4'>
                        <img src={logo} className='h-10 my-3 rounded-lg' alt="Logo" />
                        <div className="text-lg xl:text-2xl font-extrabold text-[#0D5376] dark:text-white">
                            SpeechToText.live
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
};

export default FooterComponent;
