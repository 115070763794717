import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import FirstPlan from '../components/Plans/FirstPlan';
import SecondPlan from '../components/Plans/SecondPlan';
import ThirdPlan from '../components/Plans/ThirdPlan';
import Navbar from '../components/Navbar';
import { update_user_activity } from '../redux/actions/UserAction';
import { useDispatch } from 'react-redux';
export default function Plans() {
    const token = localStorage.getItem('repeatMeToken')
    const dispatch = useDispatch()
    useEffect(() => {
        let userActivityMessage = "Plans Page"
        dispatch(update_user_activity(userActivityMessage, token))
    }, [dispatch, token]);
    return (
        <div className=''>
            <Helmet>
                <title>Purchase Plans - SpeechToText.live</title>
                <meta name="description" content="Explore and purchase different plans available on our platform." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <Navbar />

            {/* <div className='mx-auto text-center   mb-4 font-extrabold leading-none tracking-tight text-purple-700 mt-10 sm:mt-12 text-2xl md:text-3xl lg:text-3xl'>

                <Link to='/'>Myzerome</Link>
            </div> */}
            <div className='lg:grid  lg:grid-cols-3 max-w-[1100px] gap-y-[20px] lg:gap-x-[20px] mx-auto lg:mt-[100px] '>
                <FirstPlan />
                <SecondPlan />
                <ThirdPlan />
            </div>


        </div >
    )
}
