import { Circles } from 'react-loader-spinner'


import React from 'react'

export default function Loading() {
  return (
    <div className='h-[100vh] w-[100vw] flex justify-center items-center z-[9999] fixed bg-white dark:bg-black bg-opacity-[0.7]'>

      <Circles
        height="80"
        width="80"
        color="rgb(147 51 234)"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  )
}

