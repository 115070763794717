import { postDataAPI, putDataAPI } from "../../utils/FetchData"
import { GLOBALTYPES } from "./GlobalTypes"

export const FINGERPRIN_TYPE = {
    LOADING: "LOADING",
    CREDITS: "CREDITS"
    // GET_SELECTED_FEATURES: "GET_SELECTED_FEATURES",
}

export const googleLogin = (userData) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        const res = await postDataAPI(`auth/googleLogin`, userData)
        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: res?.data?.data?.token,
                user: res?.data?.data?.response,
            }
        })
        localStorage.setItem("repeatMeToken", JSON.stringify(res?.data?.data?.token))
        localStorage.setItem("userRepeatMe", JSON.stringify(res?.data?.data?.response))
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res?.data?.message
            }
        })
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const logout = () => async (dispatch) => {
    try {
        localStorage.removeItem('repeatMeToken')
        localStorage.removeItem('userRepeatMe')
        window.location.href = "/"
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.msg
            }
        })
    }
}


export const create_fingerprint = (fingerprint, source) => async (dispatch) => {
    try {
        // dispatch({ type: FINGERPRIN_TYPE.LOADING, payload: { loading: true } })
        const res = await postDataAPI(`auth/fingerPrint`, { fingerprint, source })

        dispatch({ type: FINGERPRIN_TYPE.CREDITS, payload: { credits: res?.data?.data?.response.credits, } })
        dispatch({ type: FINGERPRIN_TYPE.LOADING, payload: { loading: false } })

        return;

    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const update_fingerprint_credit = async (recordTime, fingerprint) => async (dispatch) => {
    try {

        const res = await putDataAPI(`auth/updateFingerPrintCredits`, { recordTime, fingerprint }, {})

        dispatch({
            type: FINGERPRIN_TYPE.CREDITS,
            payload: {
                credits: res?.data?.data?.response?.credits,
            }
        })
        return;
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const submit_feedback = ({ feedback, email }) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        const res = await postDataAPI(`user/submit_feedback`, { feedback, email })
        dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res?.data?.message } })
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}