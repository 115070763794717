import { USER_TYPES } from "../actions/UserAction";

const initialState = {
    loading: true,
    credits: 0,
    prompt: 0,
    toggle: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case USER_TYPES.CREDITS:
            return {
                ...state,
                credits: action.payload.credits,
                prompt: action.payload.prompt,
                promptTwo: action.payload.promptTwo,
                promptThree: action.payload.promptThree
            }
        case USER_TYPES.HOME:
            return {
                ...state,
                toggle: action.payload.toggle
            }
        default:
            return state;
    }
}

export default userReducer