import React from 'react';
import '../../styles/toggle.css'; // Import the CSS file
import { useDispatch, useSelector } from 'react-redux';
import { USER_TYPES } from '../../redux/actions/UserAction';
import { useNavigate } from 'react-router-dom';

const ToggleSwitch = () => {

    const isPro = useSelector(state => state.user?.toggle)

    const isDarkTheme = useSelector(state => state.theme?.theme)
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const handleToggle = () => {
        dispatch({
            type: USER_TYPES.HOME,
            payload: {
                toggle: !isPro // Toggle the value
            }
        });
        navigation('/');
    };
    const handleToggleBeginner = () => {
        dispatch({
            type: USER_TYPES.HOME,
            payload: {
                toggle: false // Toggle the value
            }
        });
        navigation('/');
    };
    const handleTogglePro = () => {
        dispatch({
            type: USER_TYPES.HOME,
            payload: {
                toggle: true // Toggle the value
            }
        });
        navigation('/');
    };

    return (
        <div className="toggle-container">
            <button onClick={handleToggleBeginner} className="label font-bold" style={!isDarkTheme ? { color: 'black' } : { color: 'white', }}>Beginner</button>
            <label className="switch cursor-pointer">
                <input type="checkbox" checked={isPro} onChange={handleToggle} />
                <span className="slider"></span>
            </label>
            <button onClick={handleTogglePro} className="label font-bold" style={!isDarkTheme ? { color: 'black' } : { color: 'white', }}>Pro</button>
        </div>
    );
};

export default ToggleSwitch;