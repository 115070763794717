import { PAYMENT_HISTORY_TYPES } from "../actions/PaymentAction";

const initialState = {
    payment_history: [],
}

const paymentHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_HISTORY_TYPES.PAYMENT_HISTORY:
            return {
                ...state,
                payment_history: action.payload.payment_history
            }
        default:
            return state;
    }
}

export default paymentHistoryReducer