import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from '../pages/Home'
import Plans from '../pages/Plans'
import Paypal from '../pages/Paypal'
// import Try from '../components/Home/Try'
// import Try2 from '../components/Home/Try2'
// import Navbar from '../components/Navbar'
import ProfileCard from '../components/Profile/Profile'
import PurchaseHistory from '../pages/PurchaseHistory'
import CustomerService from '../pages/CustomerService'
import TextToSpeech from '../pages/TextToSpeech'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import TermsAndConditions from '../pages/TermsandConditions'



function PrivateRoutes() {
    return (

        <Routes >
            <Route path='' element={<Home />} />
            <Route path='/plans' element={<Plans />} />
            <Route path='/paypalCheckout' element={<Paypal />} />
            <Route path='/profile' element={<ProfileCard />} />
            <Route path='/purchase-history' element={<PurchaseHistory />} />
            <Route path='/customer-service' element={<CustomerService />} />
            <Route path='/text-to-speech' element={<TextToSpeech />} />
            <Route path='/speechtotext/transcribe' element={<Navigate to="/" />} />
            <Route path='/privecypolicy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<TermsAndConditions />} />


            {/* <Route path='/try2' element={<BasicMenu />} /> */}
            {/* <Route path='/n' element={<Navbar />} /> */}
        </Routes>
    )
}

export default PrivateRoutes
