import React from 'react';
import FooterComponent from '../components/Footer/Footer';
import Navbar from '../components/Navbar';
import WithoutLoginNavbar from '../components/WithoutLoginNavbar';

const PrivacyPolicy = () => {
    let token = localStorage.getItem('repeatMeToken')
    return (
        <>
            {token ? <Navbar /> : <WithoutLoginNavbar />}
            <div className="bg-white dark:bg-black dark:text-white text-black min-h-screen p-6">
                <div className="max-w-4xl mx-auto bg-gray-100 dark:bg-black rounded-lg shadow-lg p-8">
                    <h1 className="text-4xl font-bold text-[#0D5376] mb-6 text-center md:text-left dark:text-white">Privacy Policy</h1>
                    <p className="mb-4 text-base leading-relaxed">
                        Welcome to SpeechToText.live. We value your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we collect, use, and protect your information when you use our services.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">Information We Collect</h2>

                    <h3 className="text-xl font-semibold text-[#7F5AF0] mb-2">1. Personal Identification Information</h3>
                    <p className="mb-4 text-base leading-relaxed">
                        When you register on our site or use our services, we may collect personal identification information such as your name, email address, and phone number.
                    </p>

                    <h3 className="text-xl font-semibold text-[#7F5AF0] mb-2">2. Speech Data</h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We collect the audio data you provide when you use our speech-to-text services. This data is used solely for the purpose of converting speech to text.
                    </p>

                    <h3 className="text-xl font-semibold text-[#7F5AF0] mb-2">3. Usage Data</h3>
                    <p className="mb-4 text-base leading-relaxed">
                        We may collect information on how the service is accessed and used. This usage data may include information such as your device's IP address, browser type, and version.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">How We Use Your Information</h2>
                    <p className="mb-4 text-base leading-relaxed">
                        The information we collect is used to provide and improve our services, communicate with you, and ensure the functionality and security of our service.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">How We Protect Your Information</h2>
                    <p className="mb-4 text-base leading-relaxed">
                        We implement a variety of security measures to maintain the safety of your personal information. Your data is stored on secure servers and is protected against unauthorized access, alteration, or disclosure.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">Your Consent</h2>
                    <p className="mb-4 text-base leading-relaxed">
                        By using our site, you consent to our privacy policy.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">Changes to Our Privacy Policy</h2>
                    <p className="mb-4 text-base leading-relaxed">
                        We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
                    </p>

                    <h2 className="text-2xl font-bold text-[#7F5AF0] mb-4">Contact Us</h2>
                    <p className="mb-4 text-base leading-relaxed">
                        If you have any questions about this privacy policy, please contact us through customer service.
                    </p>
                </div>
            </div>
            <FooterComponent />
        </>
    );
};

export default PrivacyPolicy;
