import { FINGERPRIN_TYPE } from "../actions/AuthAction";

const initialState = {
    loading: true,
    credits: null,
}

const fingerPrintReducer = (state = initialState, action) => {
    switch (action.type) {
        case FINGERPRIN_TYPE.LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case FINGERPRIN_TYPE.CREDITS:
            return {
                ...state,
                credits: action.payload.credits,
            }
        default:
            return state;
    }
}

export default fingerPrintReducer