import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import { googleLogin } from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import axios from 'axios'
// const BASE_URL = `http://localhost:5000`;

let REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID


function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getIp = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        console.log('data', data)
        return data
    }
    const handleGoogleUserData = async (response) => {
        let userData = jwtDecode(response.credential)
        const ip = await getIp()
        userData.ip = ip
        dispatch(googleLogin(userData)).then((res) => {
            navigate('/')
        })
    }

    const handleError = (response) => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: 'google login failed'
            }
        })
    }


    return (
        <>
            <Helmet>
                <title>Login - SpeechToText.live</title>
                <meta name="description" content="Log in to your account to access exclusive features." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <div className=''>

                <header className="fixed w-full mt-[30px]">
                    <nav className=" border-gray-200 py-2.5 ">
                        <div className="flex flex-wrap items-center max-w-screen-xl px-4 mx-auto">
                            <Link to="/" className="flex items-center">
                                <span className="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-4xl text-[#0D5376] ">SpeechToText.live</span>
                            </Link>
                        </div>
                    </nav>
                </header>

                <section className=" text-gray-800 ">
                    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                        <div className="mr-auto place-self-center lg:col-span-7" style={{ marginTop: "60px" }}>
                            <h1 className="max-w-2xl mb-4 text-xl font-extrabold leading-none tracking-tight md:text-3xl xl:text-4xl dark:text-white">Instantly Transcribe Speech Online
                                {/* <br />products & brands. */}
                            </h1>
                            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Experience seamless speech-to-text conversion with our intuitive online tool. Perfect for students, professionals, and anyone in need of quick transcription services, our Speech to Text Converter efficiently transforms your spoken words into accurately typed text.</p>
                            <h1 className="max-w-2xl mb-4 text-xl font-extrabold leading-none tracking-tight md:text-2xl xl:text-2xl dark:text-white">Sign In Now
                                {/* <br />products & brands. */}
                            </h1>
                            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                                <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                                    <GoogleLogin
                                        onSuccess={handleGoogleUserData}
                                        onError={handleError}
                                        className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                                    />
                                </GoogleOAuthProvider>

                            </div>
                        </div>
                        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                            <img src="/image/hero.png" alt="hero" />
                        </div>
                    </div>
                </section>

                {/* {display && <div className="modal-backdrop"></div>} */}
                {/* <div id="modal" className="popupContainer bg-gray-900 rounded-[5px] ">
                    <div className='flex items-center justify-center flex-col '>

                        <div className="popupHeader w-full mb-[10px] rounded-[5px] border-none">
                            <span className="header_title">Login</span>
                            
                        </div>

                        <section className="popupBody">
                            <div className="social_login">
                                <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                                    <GoogleLogin
                                        onSuccess={handleGoogleUserData}
                                        onError={handleError}
                                        className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                                    />
                                </GoogleOAuthProvider>
                            </div>
                        </section>
                    </div>

                </div> */}
            </div>

        </>

    );
}

export default Login;