import React, { useEffect, useState } from 'react'
import '../../styles/shortcut.css'
import { useSelector } from 'react-redux';
export default function ProShortcut() {
    const [isExpanded, setIsExpanded] = useState(false);
    let credits = useSelector(state => state.fingerprint?.credits)
    const [minute, setMinute] = useState(0)
    const [second, setSecond] = useState(0)

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        function formatTime(credits) {
            const minutes = Math.floor(credits / 60);
            const remainingSeconds = credits % 60;
            setMinute(minutes);
            setSecond(remainingSeconds);
        }

        if (credits !== undefined) {
            formatTime(credits);
        }
    }, [credits]);
    return (
        <div className='flex flex-col justify-center items-center text-center p-2'>
            <h1 className='text-xl font-extrabold text-[#0D5376] dark:text-white'>Speech &#8594; Text</h1>
            <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white mt-2'>Press A to start recording</h3>
            <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press S to stop and convert to text</h3>

            {isExpanded && (
                <>
                    <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press C to copy text into your clipboard</h3>
                    <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press G to copy Grammar text into your clipboard</h3>
                    <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press H to copy Summary text into your clipboard</h3>
                    <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press Z to Only transcribe the text</h3>
                    <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Credits : {minute} min {second} sec</h3>
                </>
            )}

            <button onClick={toggleExpanded} className='mt-2 text-[#0D5376]'>
                {isExpanded ? '▲ Show Less' : '▼ Show More'}
            </button>
        </div>
    );
}
