import '../styles/recording.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import Shortcut from '../components/Home/Shortcut';
import WithoutLoginNavbar from '../components/WithoutLoginNavbar';
import { create_fingerprint, FINGERPRIN_TYPE, update_fingerprint_credit } from '../redux/actions/AuthAction';
import Loading from '../components/Loader/Loading';
import { Helmet } from 'react-helmet';
import InfoIcon from '../svgicon/InfoIcon';
import { info } from '../common/popover';
import FooterComponent from '../components/Footer/Footer';
const OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY


function FreeHome() {
    let credits = useSelector(state => state.fingerprint?.credits)

    const isDarkTheme = useSelector(state => state.theme?.theme)
    const [text, setText] = useState('')
    const [isVisible, setIsVisible] = useState(true);
    const creditsLoading = useSelector((state) => state.fingerprint?.loading)

    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const timerRef = useRef(timer);
    const maxRecordingTime = credits; // 5 minutes in seconds
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    // let tempTime = 0
    const dispatch = useDispatch()
    const [textLoading, setTextLoading] = useState(false)
    // const [prompt, setPrompt] = useState("'comma' is ',' and 'dot' is '.' CAR-T cell, DALLE-3,")
    const [prompt, setPrompt] = useState("")
    const [visiblePopover, setVisiblePopover] = useState(false);

    const [fingerprint, setFingerprint] = useState('');


    const getBrowserFingerprint = async () => {
        // const navigatorInfo = window.navigator;
        // const screenInfo = window.screen;

        // const data = {
        //     userAgent: navigatorInfo.userAgent,
        //     language: navigatorInfo.language,
        //     platform: navigatorInfo.platform,
        //     deviceMemory: navigatorInfo.deviceMemory || 'unknown',
        //     hardwareConcurrency: navigatorInfo.hardwareConcurrency,
        //     colorDepth: screenInfo.colorDepth,
        //     timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //     sessionStorage: !!window.sessionStorage,
        //     localStorage: !!window.localStorage,
        //     indexedDb: !!window.indexedDB,
        //     cookiesEnabled: navigatorInfo.cookieEnabled,
        // };


        // const rawData = Object.values(data).join('::');
        // return sha256(rawData);
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            setFingerprint(data.ip); // Set the fingerprint state
            return data.ip;
        } catch (error) {
            console.error('Error fetching IP:', error);
        }
    };

    const handleMouseEnter = (id) => {
        setVisiblePopover(true);
    };

    const handleMouseLeave = () => {
        setVisiblePopover(false);
    };


    const handleClose = () => {
        setIsVisible(false);
    };
    useEffect(() => {
        let fingerprintCredit = localStorage.getItem('fingerprintCredit');
        fingerprintCredit = fingerprintCredit !== null ? Number(fingerprintCredit) : null;
        if (credits) {

            if (fingerprintCredit === null || isNaN(fingerprintCredit)) {

                // If fingerprintCredit is not present in localStorage or is NaN
                if (credits !== 0) {
                    localStorage.setItem('fingerprintCredit', credits);
                }
            } else {

                if (fingerprintCredit < credits) {
                    if (credits !== fingerprintCredit) {
                        dispatch({
                            type: FINGERPRIN_TYPE.CREDITS,
                            payload: {
                                credits: fingerprintCredit,
                            }
                        });
                    }
                } else {
                    localStorage.setItem('fingerprintCredit', credits);
                }
            }
        }
    }, [credits, dispatch]);

    useEffect(() => {
        const callFingerPrintApi = async () => {
            function getQueryParameter(name) {
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.get(name);
            }

            let source = getQueryParameter('utm_source');
            if (!fingerprint) {
                const ip = await getBrowserFingerprint();
                dispatch(create_fingerprint(ip, source))
                setFingerprint(getBrowserFingerprint());
            }
        }

        callFingerPrintApi()

    }, [dispatch, fingerprint]);



    useEffect(() => {
        let interval = null;

        if (isRecording) {
            interval = setInterval(() => {
                // tempTime = timer
                setTimer(prevTimer => {
                    if (prevTimer < maxRecordingTime) {
                        timerRef.current = prevTimer + 1;
                        return prevTimer + 1;
                    } else {
                        stopRecording();
                        timerRef.current = prevTimer;
                        return prevTimer;
                    }
                    // const newTimer = prevTimer < maxRecordingTime ? prevTimer + 1 : prevTimer;
                    // timerRef.current = newTimer; // Update the ref
                    // return newTimer;
                });
            }, 1000);
        } else {
            clearInterval(interval);
            // setTimer(0)

            // Reset timer when not recording
        }

        return () => clearInterval(interval);
    }, [isRecording, maxRecordingTime]);
    const toggleRecording = () => {
        if (!isRecording || timer >= maxRecordingTime) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const transcribeAudio = useCallback(async (audioBlob, timeParameter) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'recording.wav');
        formData.append('prompt', prompt);

        try {
            if (credits > 0) {
                setTextLoading(true);

                // Make a request to your backend API to transcribe audio
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/openai/transcribe`, {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    const result = await response.json();

                    // Update credits and store the response text
                    dispatch(await update_fingerprint_credit(timeParameter, fingerprint));
                    let fingerprintCredit = Number(localStorage.getItem('fingerprintCredit')) || 0;
                    localStorage.setItem('fingerprintCredit', fingerprintCredit - timeParameter);
                    setText(result?.text); // Set the transcribed text
                    setTimer(0); // Stop the timer
                    setTextLoading(false); // Hide loading spinner
                } else {
                    // Handle error response
                    setTextLoading(false);
                    dispatch({
                        type: GLOBALTYPES.ALERT,
                        payload: { error: 'Something went wrong with the transcription' }
                    });
                    setTimer(0);
                }
            } else {
                // Handle case where user has insufficient credits
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: { error: 'Please login to use more free credits' }
                });
                setTimer(0);
            }
        } catch (error) {
            // Handle any errors during the fetch call
            console.error('There was an error calling the transcription API', error);
            setTimer(0);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: 'Something Went Wrong' }
            });
            setTextLoading(false);
        }
    }, [credits, dispatch, fingerprint, prompt]);


    const startRecording = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (e) => {
                audioChunksRef.current.push(e.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                await transcribeAudio(audioBlob, timerRef.current);
                audioChunksRef.current = [];
            };

            mediaRecorderRef.current.start(1000);
            setIsRecording(true);
        } catch (err) {
            console.error('An error occurred during recording:', err);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err.message
                }
            })

        }

        // Start recording logic
    }, [dispatch, transcribeAudio]);
    const stopRecording = () => {
        // Stop recording logic
        mediaRecorderRef.current.stop();
        const stream = mediaRecorderRef.current?.stream;
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);

    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (document.activeElement.tagName === 'INPUT') {
                return;
            }
            if (event.key === 'a' || event.key === 'A') {
                startRecording();

            } else if (event.key === 'S' || event.key === 's' || event.key === 'space') {
                stopRecording();

            }
            // else if (event.key === 'd' || event.key === 'D') {
            //     recorderControls.togglePauseResume()
            // }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isRecording, startRecording]);


    // navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then(stream => {
    //         // console.log('Permission Granted');
    //         setIsBlocked(false);
    //     })
    //     .catch(error => {
    //         // Handle the error here
    //         if (error.name === 'NotAllowedError') {
    //             // User has blocked the microphone
    //             // console.log('Permission Denied');


    //             dispatch({
    //                 type: GLOBALTYPES.ALERT,
    //                 payload: {
    //                     error: 'Mic Permission was denied'
    //                 }
    //             })
    //             setIsBlocked(true);


    //         }
    //     });


    const handleChange = (e) => {
        setPrompt(
            e.target.value,
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setPrompt(prompt)
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: 'Prompt Added Successfully'
            }
        })

    }


    return (
        <>
            <Helmet>
                <title>Home - SpeechToText.live</title>
                <meta name="description" content="Welcome to our website. Please log in to Convert speech to text effortlessly with our advanced transcription service." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <div className='flex flex-col min-h-screen'>

                <WithoutLoginNavbar />
                {
                    creditsLoading ? <Loading /> :
                        <div className=' flex-grow'>

                            <div className='flex flex-col items-center w-full gap-4' >
                                <div className=' w-full'>

                                    {isVisible && (
                                        <div id="sticky-banner" tabIndex="-1" className="start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-black dark:border-gray-600">
                                            <div className="flex items-center mx-auto">
                                                <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                                                    <span className="inline-flex p-1 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-6 h-6 items-center justify-center flex-shrink-0">
                                                        <svg className="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                                                            <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
                                                        </svg>
                                                        <span className="sr-only">Light bulb</span>
                                                    </span>
                                                    <span className={`text-sm ${isDarkTheme ? 'text-white ' : ''}`}>
                                                        Log in now to receive more free credits and access Pro mode for free.</span>
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <button onClick={handleClose} type="button" className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                    </svg>
                                                    <span className="sr-only">Close banner</span>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-1 gap-8' >

                                    <div className=''
                                    >

                                        <Shortcut />


                                    </div>

                                    {/* </div> */}
                                    <div className={`bg-gray-100 max-w-[800px] px-[30px]  rounded-lg flex justify-center items-center flex-col gap-y-4 py-[30px] ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111]`} >
                                        {/* <div className='text-center dark:text-white mx-auto'> Credits : {minute} min {second} sec</div> */}

                                        <form onSubmit={handleSubmit} className='w-[320px] sm:w-[350px] flex items-center justify-center gap-x-2'>
                                            {visiblePopover && (
                                                <div className="absolute left-0 top-[90px] inline-block w-64 text-sm text-gray-500 z-[999999] transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                    <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                        <h3 className="font-semibold text-gray-900 dark:text-white">Info</h3>
                                                    </div>
                                                    <div className="px-3 py-2">
                                                        <p>{info[0].text}</p>
                                                    </div>
                                                    <div data-popper-arrow></div>
                                                </div>
                                            )}
                                            <InfoIcon
                                                className={`w-5 h-5 cursor-pointer ${isDarkTheme ? 'text-white' : 'text-black'}`}
                                                color={isDarkTheme ? 'white' : 'black'}
                                                onMouseEnter={() => handleMouseEnter(2)}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                            <div className="input-container text-black w-full">
                                                <input type="search" className='text-black' autoComplete='off' autoCorrect='off' id="search" style={{ color: "black" }} placeholder="Type difficult spellings: DALLE3, CRISPR, Joaquin, Saoirse, or etc" onChange={handleChange} value={prompt} />
                                                <button type="submit" className="button">Submit</button>
                                            </div>
                                        </form>

                                        <div className='flex justify-center items-center gap-5 outline-none border-none' >
                                            <button className='bg-white  outline-none rounded-full shadow-lg relative flex  gap-5 p-5' onClick={toggleRecording}>

                                                <svg className='z-[999] outline-none'
                                                    fill='black'
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path d="M439.5 236c0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 70-64 126.9-142.7 126.9-78.7 0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 86.2 71.5 157.4 163.1 166.7v57.5H212c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h88c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
                                                    <path d="M256 323.5c51 0 92.3-41.3 92.3-92.3V103.3c0-51-41.3-92.3-92.3-92.3s-92.3 41.3-92.3 92.3v127.9c0 51 41.3 92.3 92.3 92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3v127.9c0 28.8-23.5 52.3-52.3 52.3s-52.3-23.5-52.3-52.3V103.3z" />
                                                </svg>
                                            </button>
                                            {isRecording && <span className=" text-black bg-white px-3 py-[7px] shadow-lg rounded-md">
                                                {`${Math.floor(timer / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                                            </span>}
                                        </div>


                                    </div>
                                </div>

                                {text && !textLoading && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111] dark:text-white`} >

                                    {text}

                                </div>
                                }
                                {textLoading && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${!isDarkTheme ? 'box-showdow-css' : ''} dark:bg-[#111111] dark:text-white`} >


                                </div>
                                }
                            </div>
                        </div >
                }
                {!creditsLoading && <FooterComponent />}
            </div>

        </>



    )
}

export default FreeHome;