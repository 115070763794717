import React, { useEffect, useState } from 'react'
import '../../styles/shortcut.css'
import { useSelector } from 'react-redux'
function Shortcut() {
  let credits = useSelector(state => state.fingerprint?.credits)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    function formatTime(credits) {
      const minutes = Math.floor(credits / 60);
      const remainingSeconds = credits % 60;
      setMinute(minutes);
      setSecond(remainingSeconds);
    }

    if (credits !== undefined) {
      formatTime(credits);
    }
  }, [credits]);

  return (

    <div className='flex flex-col gap-y-1 justify-center items-center text-center'>
      <h1 className='text-xl font-extrabold text-[#0D5376] dark:text-white'>Speech &#8594; Text</h1>
      {/* <h1 className='text-2xl font-extrabold text-[#0D5376] dark:text-white'>Convert spoken words to written text</h1> */}
      <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white mt-3'>Press A to start recording</h3>
      <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press S to stop and convert to text</h3>
      {isExpanded && (
        <>
          <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Press C to copy text into your clipboard</h3>
          <h3 className='text-sm font-extrabold text-[#0D5376] dark:text-white'>Credits : {minute} min {second} sec</h3>
        </>
      )}
      <button onClick={toggleExpanded} className='mt-2 text-[#0D5376]'>
        {isExpanded ? '▲ Show Less' : '▼ Show More'}
      </button>
    </div>
  )
}

export default Shortcut