import '../styles/recording.css'
import React from 'react'
import { useSelector } from 'react-redux';
import Navbar from '../components/Navbar';
import BasicHome from '../components/Home/BasicHome';
import ProHome from '../components/Home/ProHome';
import FooterComponent from '../components/Footer/Footer';


function Home() {
    const isPro = useSelector(state => state.user?.toggle)
    const creditsLoading = useSelector(state => state.user?.loading)

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
                {isPro ? <ProHome /> : <BasicHome />}
            </div>
            {!creditsLoading && <FooterComponent />}

        </div>
    )
}

export default Home;