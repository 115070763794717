import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit_feedback } from '../redux/actions/AuthAction';
import { Helmet } from 'react-helmet';
import { update_user_activity } from '../redux/actions/UserAction';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';

export default function CustomerService() {
    const token = localStorage.getItem('repeatMeToken')
    const [inputValue, setInputValue] = useState('');
    const user = localStorage.getItem('userRepeatMe')
    const email = JSON.parse(user).email
    const dispatch = useDispatch()

    const isDarkTheme = useSelector(state => state.theme?.theme)

    useEffect(() => {
        let userActivityMessage = "Customer Service"
        dispatch(update_user_activity(userActivityMessage, token))
    }, [dispatch, token]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!inputValue) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please enter your question'
                }
            })
            return;
        }
        // Perform some action with the input value
        dispatch(submit_feedback({ feedback: inputValue, email }));
        setInputValue('');
    };

    return (
        <>
            <Helmet>
                <title>Customer Service Authorized - SpeechToText.live</title>
                <meta name="description" content="Get in touch with our customer service for support." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <Navbar />
            <div className="flex flex-col items-center justify-center mx-auto mt-8  text-center">
                <form className="flex flex-col items-start max-w-[350px]" onSubmit={handleSubmit}>
                    <textarea
                        className={`border-2 h-[400px] border-gray-300 rounded-md px-4 py-6 mb-4 w-[340px] outline-none ${isDarkTheme ? 'border-gray-500' : 'border-gray-300'}`}
                        rows="5"
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        placeholder="Enter your question"
                        style={{ backgroundColor: isDarkTheme ? 'black' : 'white', color: isDarkTheme ? 'white' : 'black' }}
                    />
                    <button className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded" type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </>
    );
}
