export const planName = {
    one: "Basic",
    two: "Plus",
    three: "Premium",
    // four: "Celebrity"
}
export const plansDefault = [
    {
        planType: planName['one'],
        price: '5',
        credits: '60000',
        WithoutDiscountPrice: '8',
        features: [
            ['1000 Minutes']

        ],
        notInclude: [

        ]
    },
    {
        planType: planName['two'],
        price: '20',
        credits: '300000',
        WithoutDiscountPrice: '30',
        features: [
            ['5000 Minutes']
        ],
        notInclude: [

        ]
    },
    {
        planType: planName['three'],
        price: '50',
        credits: '900000',
        WithoutDiscountPrice: '75',
        features: [
            ['15000 Minutes']
        ],
        notInclude: [

        ]
    },
];