export const info = [
    {
        id: 1,
        text: "Write a difficult word that is hard to transcribe, e.g., CAR-T cell, DALLE-3,"
    },
    {
        id: 2,
        text: "Give a prompt for the second block to enhance the first block sentence, e.g., Rewrite the sentence. Don't change much, only correct the grammar."
    },
    {
        id: 3,
        text: "Give a prompt for the third block to enhance the second block sentence, e.g., Give summarized answer and give example"
    },
]