import React from 'react';
import FooterComponent from '../components/Footer/Footer';
import Navbar from '../components/Navbar';
import WithoutLoginNavbar from '../components/WithoutLoginNavbar';

const TermsAndConditions = () => {
    let token = localStorage.getItem('repeatMeToken')

    return (
        <>
            {token ? <Navbar /> : <WithoutLoginNavbar />}

            <div className="bg-white dark:bg-black dark:text-white min-h-screen py-6 px-4 text-gray-800 font-poppins">
                <div className=" mx-auto">
                    <div className="bg-gray-100 dark:bg-black p-6 border border-gray-300 dark:border-black rounded-lg shadow-md mb-8">
                        <h1 className="text-4xl text-center font-bold text-[#0D5376] dark:text-white mb-4">Terms and Conditions</h1>

                        <p className="mb-4 text-base leading-relaxed">
                            Welcome to SpeechToText.live. These terms and conditions outline the rules and regulations for the use of our website and services.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            By accessing this website, you accept these terms and conditions in full. Do not continue to use SpeechToText.live if you do not agree to all of the terms and conditions stated on this page.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">License</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            Unless otherwise stated, SpeechToText.live and/or its licensors own the intellectual property rights for all material on SpeechToText.live. All intellectual property rights are reserved.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            You may view and/or print pages from SpeechToText.live for your own personal use subject to restrictions set in these terms and conditions.
                        </p>

                        <p className="mb-4 text-base leading-relaxed">
                            You must not:
                        </p>
                        <ul className="list-disc list-inside mb-4 pl-6">
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Republish material from SpeechToText.live</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Sell, rent, or sub-license material from SpeechToText.live</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Reproduce, duplicate, or copy material from SpeechToText.live</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Redistribute content from SpeechToText.live (unless content is specifically made for redistribution)</li>
                        </ul>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">User Comments</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material, and data ('Comments'). SpeechToText.live does not screen, edit, publish, or review Comments prior to their appearance on the website and Comments do not reflect the views or opinions of SpeechToText.live, its agents, or affiliates.
                        </p>
                        <p className="mb-4 text-base leading-relaxed">
                            Comments reflect the view and opinion of the person who posts such view or opinion.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Hyperlinking to our Content</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            The following organizations may link to our website without prior written approval:
                        </p>
                        <ul className="list-disc list-inside mb-4 pl-6">
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Government agencies</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Search engines</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> News organizations</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Online directory distributors</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Systemwide Accredited Businesses</li>
                        </ul>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Content Liability</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your website. No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Reservation of Rights</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms and conditions.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Removal of links from our website</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Disclaimer</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose, and/or the use of reasonable care and skill).
                        </p>
                        <ul className="list-disc list-inside mb-4 pl-6">
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Limit or exclude our or your liability for death or personal injury resulting from negligence</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Limit or exclude our liability for fraud or fraudulent misrepresentation</li>
                            <li className="flex items-start mb-2"><span className="mr-2 mt-1">•</span> Limit any of our or your liabilities in any way that is not permitted under applicable law</li>
                        </ul>

                        <p className="mb-4 text-base leading-relaxed">
                            By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable. If you do not think they are reasonable, you must not use this website.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Variation of Terms</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            SpeechToText.live is permitted to revise these Terms at any time as it sees fit, and by using this website you are expected to review these Terms on a regular basis.
                        </p>

                        <h2 className="bg-[#EDE7F6] dark:bg-black text-[#7F5AF0] p-2 mb-4 text-2xl">Governing Law & Jurisdiction</h2>
                        <p className="mb-4 text-base leading-relaxed">
                            These Terms will be governed by and interpreted in accordance with the laws of the State of Example, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Example for the resolution of any disputes.
                        </p>
                    </div>
                </div>
            </div>

            <FooterComponent />
        </>
    );
};

export default TermsAndConditions;
