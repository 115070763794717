import { postDataAPI } from "./FetchData"

export const uploadAudio = async (formData) => {
    // dispatch(chatLoader(true))

    const res = await postDataAPI(`aws/uploadAudio`,
        formData
        , {})
    const data = res.data
    // dispatch(chatLoader(false))

    return data
}
