import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../utils/FetchData';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import { paypal_recharge } from '../redux/actions/PaymentAction';
import { Circles } from 'react-loader-spinner';
import Navbar from '../components/Navbar';
import { Helmet } from 'react-helmet';
import { update_user_activity } from '../redux/actions/UserAction';
const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;


export default function Paypal() {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const selectedPrice = location.state?.price;
    const index = location.state?.index;
    const credits = location.state?.credits;
    const token = localStorage.getItem('repeatMeToken')

    const planType = index === 1 ? "Basic" : index === 2 ? "Plus" : "Premium"

    useEffect(() => {
        let userActivityMessage = `Paypal Screen ${planType}`
        dispatch(update_user_activity(userActivityMessage, token))
    }, [dispatch, token, planType]);

    useEffect(() => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
    }, [dispatch])
    const createOrder = async (data, actions) => {
        try {
            const response = await axios.post(`${BASE_URL}/user/create_order`, { selectedPrice },
                {
                    headers: { Authorization: token }
                });

            return response.data.id;
        } catch (error) {
            let userActivityMessage = `Order creation failed`
            dispatch(update_user_activity(userActivityMessage, token))
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Order creation was unsuccessful.'
                }
            })
        }
    };

    const onApprove = async (data, actions) => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
        data.index = index
        data.price = selectedPrice
        data.credits = credits
        dispatch(await paypal_recharge({ data, token })).then(() => {
            let userActivityMessage = `* Payment Complete ${planType}`
            dispatch(update_user_activity(userActivityMessage, token))
            navigate('/')
        }).catch((err) => {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err?.response?.data?.message
                }
            })
        })
    }
    return (
        <div className=''>
            <Helmet>
                <title>{`Payment Checkout ${planType} - SpeechToText.live`}</title>
                <meta name="description" content="Proceed to purchase credits on our platform." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <Navbar />

            {/* <div className='mx-auto text-center   mb-4 font-extrabold leading-none tracking-tight text-purple-700 mt-10 sm:mt-12 text-2xl md:text-3xl lg:text-3xl'>

                <Link to='/'>Myzerome</Link>
            </div> */}
            <div className=' shadow-white flex flex-col  rounded-[10px] bg-white mx-auto max-w-[400px] p-[20px] text-white mt-40 dark:bg-[#111111]' style={{ boxShadow: "0px 5px 5px #00000041 " }} >
                <>
                    <div className='text-black dark:text-white mx-auto my-[20px] font-bold'>Payment : ${selectedPrice}</div>
                    <PayPalScriptProvider options={{
                        'client-id': clientId,
                        currency: "USD"
                    }}>
                        <ButtonWrapper createOrder={createOrder}
                            onApprove={onApprove} />
                    </PayPalScriptProvider>
                </>

            </div>
        </div>
    )
}

const ButtonWrapper = ({ createOrder, onApprove }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>
            {(isPending) &&
                <div className='flex justify-center'>

                    <Circles
                        height="50"
                        width="50"
                        color="rgb(147 51 234)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>}
            <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}

            />
        </>
    );
}

