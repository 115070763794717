import React, { useState } from 'react'
import { BsQuestionLg, BsXCircleFill } from 'react-icons/bs';
import { Popover, ButtonToolbar, OverlayTrigger, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { plansDefault } from '../../common/plan';
import { MdStarPurple500 } from 'react-icons/md';

export default function ThirdPlan() {
    const [plans, setPlans] = useState(plansDefault)
    const navigate = useNavigate()

    const handleThirdPurchase = (price, inde, credits) => {
        // if (activePlan?.index === inde) {
        navigate('/paypalCheckout', {
            state: { price: price, index: inde, credits: credits }
        })
        // }
        // else {
        //     dispatch({
        //         type: GLOBALTYPES.ALERT,
        //         payload: {
        //             error: 'Please Subscribe The Plan'
        //         }
        //     })
        // }
    }
    return (
        <div className='max-w-[370px] mx-auto lg:mx-[unset] mt-[20px] bg-gray-100 rounded-[30px] p-[40px] thirdPlan dark:bg-[#111111]'>


            <header className="flex gap-[10px] flex-col items-start  mb-[10px]">

                <h3 className=" text-pink-500  font-bold text-[28px]">
                    {plans[2].planType}</h3>

            </header>
            <div className="font-bold text-pink-500 ">
                <div className="h-[100px] flex items-center  gap-[1.5px]">
                    <div className="text-[28px] mb-[15px]">$</div>
                    <div className='text-[60px] text-black dark:text-white'>{plans[2].price}</div>
                    <div className='items-start flex flex-col justify-start mb-8'>
                        <div className='text-[16px] line-through  dark:text-white text-black'>${plans[2].WithoutDiscountPrice}</div>
                    </div>
                </div>


            </div>

            <ul>
                <div className='overflow-y-auto scrollable-line-css-price mt-[25px] text-gray-300'>
                    {plans[2]?.features?.map((feature, index) => {
                        return <div key={index} className='flex items-start justify-between'>

                            <div className='flex items-start justify-between  gap-[15px] mb-[12px]'>
                                <MdStarPurple500 className='h-[25px] w-[25px] text-pink-400 flex-shrink-0' />

                                <div className='text-start dark:text-white text-black text-[17px]'>{feature[0]}</div>
                            </div>
                            {/* <ButtonToolbar>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={
                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={feature[1]}>
                                            {feature[1]}
                                        </Popover>
                                    }
                                >
                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                        </div>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonToolbar> */}

                        </div>
                    })}
                    {plans?.notInclude?.map((data, index) => (
                        <div key={index} className='flex items-start justify-between mb-[12px]'>
                            <div className='flex items-start gap-[15px] '>
                                <BsXCircleFill className='h-[17px] w-[17px] text-red-400 flex-shrink-0 mt-[6px]' />
                                <div className='text-start text-[17px]'>demo</div>
                            </div>
                            <ButtonToolbar>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={
                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} >
                                            'demo'
                                        </Popover>
                                    }
                                >
                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                        </div>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </div>
                    ))}
                </div>
            </ul>



            <hr className='text-black border-1 border-pink-500 my-[30px]' />

            <button className='relative w-full mt-[15px] border-solid border-pink-500 border-[1px] bg-pink-300 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => handleThirdPurchase(plans[2].price, 3, plans[2].credits)}>
                <span>Purchase Credits</span>
                {/* <div className='absolute top-0 bottom-0 right-[10px] flex items-center justify-center'>
                    <ButtonToolbar>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            overlay={
                                <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={`objectQ['additionRechargeQ']`}>
                                    {`objectQ['additionRechargeQ']`}
                                </Popover>
                            }
                        >
                            <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-[2px] border-solid border-black  '>

                                    <BsQuestionLg className='h-[10px] w-[10px] text-black flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                </div>
                            </Button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                </div> */}
            </button>
        </div>
    )
}
