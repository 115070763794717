import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WithoutLoginNavbar from '../components/WithoutLoginNavbar';
import { submit_feedback } from '../redux/actions/AuthAction';
import { Helmet } from 'react-helmet';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';

export default function FreeCustomerService() {
    const [inputValue, setInputValue] = useState('');
    const [email, setEmail] = useState('');

    const isDarkTheme = useSelector(state => state.theme?.theme)
    const dispatch = useDispatch()
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        } else {
            setInputValue(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please enter your email'
                }
            })
            return;
        }
        if (!inputValue) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please enter your feedback'
                }
            })
            return;
        }
        dispatch(submit_feedback({ feedback: inputValue, email }));
        setInputValue('');
        setEmail('');
        // Perform some action with the input value and email
    };

    return (
        <>
            <Helmet>
                <title>Customer Service - SpeechToText.live</title>
                <meta name="description" content="Get in touch with our customer service for support." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            <WithoutLoginNavbar />
            <div className="flex flex-col items-center justify-center mx-auto mt-8  text-center">
                <form className="flex flex-col items-start max-w-[350px]" onSubmit={handleSubmit}>
                    <input
                        className={`mb-4 w-[340px] outline-none px-4 py-2 border-2 border-gray-300 rounded-md ${isDarkTheme ? 'border-gray-500' : 'border-gray-300'}`}
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        style={{ backgroundColor: isDarkTheme ? 'black' : 'white', color: isDarkTheme ? 'white' : 'black' }}
                    />
                    <textarea
                        className={`border-2 h-[400px] border-gray-300 rounded-md px-4 py-6 mb-4 w-[340px] outline-none ${isDarkTheme ? 'border-gray-500' : 'border-gray-300'}`}
                        rows="5"
                        type="text"
                        name="question"
                        value={inputValue}
                        onChange={handleChange}
                        placeholder="Enter your question"
                        style={{ backgroundColor: isDarkTheme ? 'black' : 'white', color: isDarkTheme ? 'white' : 'black' }}
                    />

                    <button className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded" type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </>
    );
}

