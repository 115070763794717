import { GLOBALTYPES } from "../actions/GlobalTypes";

const initialState = {
    timer: 0,
}

const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GLOBALTYPES.TIMER:
            return {
                ...state,
                timer: action.payload.timer
            }
        default:
            return state;
    }
}

export default timerReducer