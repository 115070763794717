import React from 'react'

export default function InfoIcon(props) {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 50 50"
            className={props.className}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            fill={props.color}
        >
            <path d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4zm0 7a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3zm-4 10v2h2v13h-2v2h8v-2h-2V21h-6z" />
        </svg>
    )
}
