import { combineReducers } from 'redux'
import auth from './authReducer'
import alert from './alertReducer'
import user from './userReducer'
import theme from './themeReducer'
import modal from './modalReducer'
import timer from './timerReducer'
import fingerprint from './fingerPrintReducer'
import payment from './paymentReducer'

export default combineReducers({
    auth,
    alert,
    user,
    theme,
    modal,
    timer,
    fingerprint,
    payment
})