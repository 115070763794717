import './App.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { logout } from './redux/actions/AuthAction';
import AuthRoutes from './routing/AuthRoutes';
import PrivateRoutes from './routing/PrivateRoutes';
import Alert from './components/Toast/Alert';
import TagManager from 'react-gtm-module'
let gtmId = process.env.REACT_APP_GTM_ID


const tagManagerArgs = {
  gtmId: gtmId
}
TagManager.initialize(tagManagerArgs)

function App() {
  let userData = localStorage.getItem('userRepeatMe')
  userData = JSON.parse(userData)


  // window.dataLayer.push({
  //   event: 'event',
  //   eventProps: {
  //     category: category,
  //     action: action,
  //     label: label,
  //     value: value
  //   }
  // });
  let tokenState = localStorage.getItem('repeatMeToken');
  let bool = false
  if (tokenState) {
    bool = true
  }
  const [currentUser, setCurrentUser] = useState(bool);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  useEffect(() => {
    if (userData) {

      window.dataLayer.push({
        event: 'pageview',
        userId: userData._id
      });
    }
    else {

      window.dataLayer.push({
        event: 'pageview',
      });
    }
    // window.dataLayer.push({
    //   event: 'pageview'
    // });
    const token = localStorage.getItem('repeatMeToken');
    const isLoggedIn = token;
    setCurrentUser(isLoggedIn);



    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp <= currentTime) {
          dispatch(logout());
        }
      }
    };

    checkTokenExpiration();
  }, [dispatch, auth, userData]);
  return (
    <>
      <Alert />
      <Router>
        <Routes>
          {
            !currentUser ?
              <>
                <Route path='/*' element={<AuthRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
              :
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
          }
        </Routes>
      </Router>
    </>
  );
}

export default App;
